import * as React from 'react';
import { Collapse, Nav, Navbar, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { useMemo, useState } from 'react';
import styled from 'styled-components';
import Link from 'next/link';

interface IRoute {
  label: string;
  uri: string;
}

const routes: IRoute[] = [
  { label: 'Willkommen', uri: '/' },
  { label: 'Aktuell', uri: '/aktuell' },
  { label: 'Philosophie', uri: '/philosophie' },
  { label: 'Kontakt', uri: '/kontakt' },
  { label: 'Angebot / Anmeldung', uri: '/angebot-anmelden' },
  { label: 'Über mich', uri: '/ueber-mich' },
  { label: 'Babydecken', uri: 'https://schuschu.top' }
];

const NavItemStyles = styled.div`
  font-size: 16px;
`;

const CustomNavLink: React.FC<IRoute> = (props) => {
  return (
    <NavItemStyles key={props.uri}>
      <NavItem>
        <Link href={props.uri} passHref>
          <NavLink>{props.label}</NavLink>
        </Link>
      </NavItem>
    </NavItemStyles>
  );
};

export const BrandedNavBar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = (): void => setIsOpen(!isOpen);

  const links = useMemo(() => {
    return routes.map((value) => {
      return <CustomNavLink key={value.uri} {...value} />;
    });
  }, []);

  return (
    <Navbar style={{ paddingLeft: 0 }} sticky={'top'} light expand={'lg'}>
      <NavbarToggler style={{ paddingRight: '5px' }} onClick={toggle} />
      <Collapse isOpen={isOpen} navbar>
        <Nav className={'mr-auto'} navbar>
          {links}
        </Nav>
      </Collapse>
    </Navbar>
  );
};
