import React from 'react';
import Head from 'next/head';

const Meta: React.FC = () => (
  <Head>
    <title>Tierli-Spielgruppe</title>
    <link href={'https://fonts.googleapis.com/css2?family=Amatic+SC&display=swap'} rel={'stylesheet'} />
    <meta charSet={'UTF-8'} />
    <meta content={'IE=edge'} httpEquiv={'X-UA-Compatible'} />
    <meta
      name={'description'}
      content={
        'Spielgruppe mit Tieren in Zürich-Altstetten. Wir kümmern uns um die Tiere, spielen, basteln, malen, singen und haben jede Menge Spass in der Natur. Es hat noch Plätze frei.'
      }
    />
    <meta name={'keywords'} content={'Spielgruppe, Altstetten, Tiere, Natur, Kinder, Zuerich'} />
    <meta name={'viewport'} content={'width=device-width, initial-scale=1.0'} />
  </Head>
);

export default Meta;
