import { ITheme } from './ITheme';

const lightTheme: ITheme = {
  maxWidth: '1280px',
  mdSize: '48rem',

  backgroundColor1: 'rgb(215, 192, 175)',
  backgroundColor2: 'rgba(106, 82, 80, 0.83)',
  headerColor: 'rgb(119, 119, 119)',
  pageTitleColor: 'rgb(51, 51, 51)',
  mainTextColor1: 'rgb(247, 178, 170)',
  mainTextColor2: 'rgb(124, 78, 64)',
  mainTextColor3: 'rgb(71,44,12)',
  mainTextTitleColor: 'rgb(247, 178, 170)'
};

export default lightTheme;
